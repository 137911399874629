import styled from 'styled-components';
import { LazyImage } from '@/internal/Image/Lazy';
import { ButtonSvgCircleFill } from '@/general/button/svg-circle-fill';
import { getMediaQuery } from '@/utils/styles/getMediaQuery';
import { Column, Row } from '../global';
import { resetButtonStyles } from '@/utils/styles/resetButtonStyles';

export const Item = styled(Row)`
  font-weight: 500;
  color: var(--color-white);

  @media ${getMediaQuery('smMin')} {
    font-size: 14px;
    line-height: 24px;
  }
  @media ${getMediaQuery('xs')} {
    font-size: 12px;
    line-height: 20px;
  }

  ${Column} {
    padding-top: 14px;
    padding-bottom: 14px;

    &:first-child {
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: var(--row-width);
        height: 100%;
        box-sizing: border-box;
        background: var(--color-grey-1150);
        border: 1px solid var(--color-grey-800);
        border-radius: 8px;
      }
    }

    > * {
      position: relative;
    }
  }
`;

export const Pool = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  font-weight: 500;
  color: var(--color-white);

  @media ${getMediaQuery('smMin')} {
    font-size: 16px;
    line-height: 28px;
  }
  @media ${getMediaQuery('xs')} {
    font-size: 14px;
    line-height: 14px;
  }
`;

export const PoolIcon = styled.div`
  position: relative;
  flex-shrink: 0;

  @media ${getMediaQuery('smMin')} {
    width: 32px;
    height: 32px;
  }
  @media ${getMediaQuery('xs')} {
    width: 30px;
    height: 30px;
  }
`;

export const PoolIconImage = styled(LazyImage).attrs({ pos: 'contain' })``;

export const PoolInfo = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media ${getMediaQuery('smMin')} {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  @media ${getMediaQuery('xs')} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media ${getMediaQuery('lgMin')} {
    margin-left: 24px;
    gap: 12px;
  }
  @media ${getMediaQuery('md')} {
    margin-left: 12px;
    gap: 6px;
  }
  @media ${getMediaQuery('smMax')} {
    margin-left: 8px;
    gap: 6px;
  }
`;

export const PoolName = styled.div`
  margin: 0;
  font-weight: 500;
  color: var(--color-white);

  @media ${getMediaQuery('smMin')} {
    font-size: 16px;
    line-height: 28px;
  }
  @media ${getMediaQuery('xs')} {
    font-size: 14px;
    line-height: 14px;
  }
`;

export const DepositButton = styled(ButtonSvgCircleFill).attrs({
  hasStaticFill: false,
  variant: 'gradient',
  size: 'small',
  fullWidth: true,
})`
  padding-left: 0;
  padding-right: 0;
  --button-size: 40px;

  font-weight: 600;
  font-size: 14px;
  line-height: 22px;

  @media ${getMediaQuery('xxl')} {
    width: 200px;
  }
  @media ${getMediaQuery('xlMax')} {
    width: 138px;
  }
`;

export const MoreDetails = styled.a`
  ${resetButtonStyles}

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 40px;
  transform: rotate(-90deg);

  svg {
    width: 22px;
    height: 22px;
  }

  path {
    fill: var(--color-white);
  }

  rect {
    stroke: var(--color-white);
  }
`;

import React, { FC, useContext } from 'react';
import { Context } from '@/store/context';
import {
  MetamaskButton,
  MetamaskButtonIcon,
  UpgradeButton,
  UpgradeButtonIcon,
} from './styles';

export const LayoutNavButtons: FC = () => {
  const ctx = useContext(Context);
  const { addToMetamaskCallback, upgradeTokenHref } = ctx;

  return (
    <>
      {addToMetamaskCallback && (
        <MetamaskButton
          tag="button"
          onClick={() => {
            addToMetamaskCallback();
          }}
        >
          <MetamaskButtonIcon aria-hidden />
          <span>Add LFI to Metamask</span>
        </MetamaskButton>
      )}

      {upgradeTokenHref && (
        <UpgradeButton tag="a" href={upgradeTokenHref}>
          <UpgradeButtonIcon useOutline aria-hidden />
          <span>Token Upgrade</span>
        </UpgradeButton>
      )}
    </>
  );
};

import React, { FC, HTMLAttributes } from 'react';

export const Icon: FC<HTMLAttributes<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0003 18.9918C14.8381 17.6436 16.8 14.7511 16.8 11.4003C16.8 8.04947 14.838 5.15689 12.0001 3.80872C9.16221 5.15689 7.2002 8.04947 7.2002 11.4003C7.2002 14.751 9.16206 17.6435 11.9998 18.9917C10.9087 19.51 9.68821 19.8 8.39999 19.8C3.7608 19.8 0 16.0392 0 11.4C0 6.7608 3.7608 3 8.39999 3C9.68839 3 10.909 3.29007 12.0002 3.80847C13.0913 3.29015 14.3119 3.00012 15.6002 3.00012C20.2394 3.00012 24.0002 6.76092 24.0002 11.4001C24.0002 16.0393 20.2394 19.8001 15.6002 19.8001C14.3119 19.8001 13.0914 19.5101 12.0003 18.9918Z"
      fill="#00FFF4"
    />
  </svg>
);
